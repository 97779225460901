.navbar {
  width: 100%;
  height: 100px;
  background-color: #9fffab;
  display: flex;
  flex-direction: row;
  opacity: 1;
  position: fixed;
  z-index: 1;
}

.navbar .leftSide {
  flex: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 50px;
}

.navbar .leftSide img {
  /* width: 70px; */
}

.navbar .rightSide {
  flex: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar a {
  color: rgb(2, 129, 34);
  text-decoration: none;
  margin: 20px;
}

.navbar .rightSide button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  height: auto;
  position: fixed;
  display: none;
}

.navbar .rightSide svg {
  font-size: 40px;
}

.navbar #open {
  padding-left: 0px;
}
.navbar #open img {
  display: none;
}

.navbar #close img {
  display: inherit;
}

.navbar #open .hiddenLinks {
  display: inherit;
  margin-left: 30px;
}

.navbar #close .hiddenLinks {
  display: none;
}

.navbar #open a {
  width: 70px;
  margin: 5px;
}
@media only screen and (max-width: 1200px) {
  .navbar .rightSide a {
    width: 70px;
    display: none;
  }
  /* .navbar .rightSide  {
    width: 70px;
    display: none;
  } */
  .navbar .leftSide {
    padding-left: 50px;
  }
  .navbar .rightSide button {
    display: inherit;
    position: fixed;
  }
}
@media only screen and (max-width: 900px) {
  .navbar .rightSide a {
    width: 70px;
    display: none;
  }
  /* .navbar .rightSide  {
    width: 70px;
    display: none;
  } */
  .navbar .leftSide {
    padding-left: 50px;
  }
  .navbar .rightSide button {
    display: inherit;
    position: fixed;
  }
}

@media only screen and (max-width: 600px) {
  .navbar .rightSide a {
    display: none;
  }

  .navbar .rightSide {
    justify-content: flex-end;
    padding-right: 50px;
  }

  .navbar .rightSide button {
    display: inherit;
    position: fixed;
    /* top: 0; */
  }
}

@media only screen and (min-width: 600px) {
  .navbar .rightSide button {
    /* display: none; */
    position: relative;
  }

  .hiddenLinks {
    display: none;
  }
}

.Logo {
  width: auto;
  height: auto;
  display: flex;
  max-width: 200px;
  /* display: inline; */
}
