.home {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  background-image: url("../assets/7.gif");
  text-shadow: 1px 1px 2px black, 0 0 25px rgb(5, 112, 32), 0 0 5px rgb(81, 226, 117);
}

.headerContainer {
  width: auto;
  height: 100%;
  margin-left: 50px;
}

.headerContainer h1 {
  justify-content: center;
  align-items: center;
  font-size: 90px;
  padding-top: 150px;
  height: auto;
  font-weight: 50;
  color: #08e23e;
}

.headerContainer p {
  padding-left: 15px;
  font-size: 40px;
  padding-top: 50px;
  font-weight: lighter;
  color: #08e23e;
}

.headerContainer button {
  color: rgb(2, 129, 34);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  background-color: rgb(35, 190, 74);
  height: 50px;
  width: 180px;
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.headerContainer button:hover {
  background-color: rgb(60, 199, 95);
  cursor: pointer;
  transition: 0.3s ease-in;
}

@media only screen and (max-width: 650px) {
  .home {
    justify-content: center;
    align-items: center;
  }
  .headerContainer h1,
  .headerContainer p {
  }

  .headerContainer {
    margin-left: 0px;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: rgb(123, 252, 155); */
    color: white;
    /* opacity: 0.5; */
  }

  .headerContainer h1 {
    font-size: 40px;
    height: 30px;
    color: white;
  }

  .headerContainer p {
    font-size: 30px;
    color: white;
  }

  .headerContainer button {
    background-color: white;
    color: #121619;
    margin-bottom: 30px;
  }

  .headerContainer button:hover {
    background-color: rgb(225, 225, 225);
    color: #121619;
  }
}
