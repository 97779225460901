.footer {
  width: 100%;
  height: 300px;
  background-color: #9fffab;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  /* margin-top: 50px; */
}

/* .socialMedia svg {
  color: white;
  margin: 5px;
  font-size: 70px;
  cursor: pointer;
  width: 50px;
} */

/* .socialMedia {} */

.footer p {
  color: rgb(0, 0, 0);
}

.shakode {
  background-color: #9fffab;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icons i{
  padding: 0.5rem;
}
