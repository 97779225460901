.slide-container {
    /* display: flex; */
    width: auto;
    justify-content: center;
    align-items: center;
    /* padding-left: 200px; */
    height: 400px;
    object-fit: fill;
    /* z-index: 1; */
    box-shadow: 2px 2px 1px 1px;
}

@media only screen and (max-width: 900px) {
    .slide-container {
        width: 700px;
        justify-content: center;
        align-items: center;
        padding-left: 200px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .slide-container {
        width: 500px;
        justify-content: center;
        align-items: center;
        object-fit: fill;
        /* padding-left: -1600px; */
    }
  }